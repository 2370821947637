<template>
  <b-container fluid>
    <button-back :redirect="`/tutorial/${record?.moduleUuid}`" />
    <b-row>
      <b-col cols="12">
        <h3>{{ record?.name }}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="isBusy">
      <b-col lg="12" md="12" sm="12">
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </b-col>
    </b-row>
    <section v-else>
      <b-card class="p-0" no-body>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="record?.link"
          allowfullscreen
        ></b-embed>
      </b-card>
    </section>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
  BCard,
  BEmbed
} from "bootstrap-vue";
import ButtonBack from "@/components/buttons/ButtonBack";

export default {
  inject: ["tutorialRepository"],
  components: {
    BContainer,
    BRow,
    BCol,
    BSpinner,
    ButtonBack,
    BCard,
    BEmbed
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      repository: "tutorialRepository",
      record: null,
      isBusy: true,
    };
  },
  methods: {
    async handleLoadTutorial() {
      const me = this;
      me.record = null;

      try {
        me.isBusy = true;

        const { data } = await me.tutorialRepository.find(me.uuid);
        me.record = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
  async mounted() {
    const me = this;
    me.handleLoadTutorial();
  },
};
</script>
