<template>
  <b-modal id="message-success" hide-footer centered size="md">
    <template v-slot:modal-header>
      <h5>Te has registrado exitosamente</h5>
      <button class="btn__close" @click="close()">
        <b-icon-x font-scale="2" />
      </button>
    </template>
    <b-container>
      <b-row class="mb-2">
        <b-col lg="12" md="12" sm="12" class="mb-1">
          <div class="content-message">
            <h1>
              ¡Bienvenido(a) a Guiia Tour - Tu Socio en la Gestión Eficiente!
            </h1>
            <p>
              En minutos recibiras un mensaje de whatsapp al número que acabas
              de registrar.
            </p>
            <p>
              Si tienes alguna pregunta o necesitas ayuda, no dudes en ponerte
              en contacto con nuestro equipo de soporte al
              <strong>+57 300 2859182</strong> Estamos aquí para ayudarte en
              cualquier momento.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { BCol, BRow, BModal, BIconX, BContainer } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BModal,
    BIconX,
    BContainer,
  },
  methods: {
    async close() {
      const me = this;
      me.$router.push("/login");
    },
  },
};
</script>
<style lang="css">
.content-message h1 {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.content-message p {
  font-size: 18px;
}
</style>
