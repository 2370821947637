<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="email" class="label_input">Correo electrónico*</label>
          <b-form-input
            id="email"
            type="email"
            size="sm"
            placeholder="Correo eléctronico"
            v-model="$v.formData.email.$model"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.email) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Género*</label>
          <v-select
            :options="typeGenders"
            label="value"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.gender"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";
import { mapState } from "vuex";

export default {
  inject: ["ownerRepository", "employeeRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    profileUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        gender: "",
        address: "",
        cellphone: "",
        email: "",
        names: "",
        last_names: "",
        type_identification: "",
        identification: "",
        userUuid: "",
      },
      typeIndetificatios: ["Cédula de ciudadanía", "Pasaporte"],
      typeGenders: ["Femenino", "Masculino", "Otro"],
    };
  },
  computed: {
    ...mapState("user", ["user", "enterprise"]),
  },
  validations: {
    formData: {
      gender: {
        required,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: {
        required,
        email,
      },
    },
  },
  async mounted() {
    const me = this;
    me.formData = { 
      gender: me.record?.gender,
      address: me.record?.address,
      cellphone: me.record?.cellphone,
      email: me.record?.email,
      names: me.record?.names,
      last_names: me.record?.last_names,
      type_identification: me.record?.type_identification,
      identification: me.record?.identification,
      userUuid: me.record?.userUuid,
    };
  },
  methods: {
    async saveData() {
      const me = this;
      let repository = null;
      if (me.profileUuid == "bcaa1cc2-72f3-4183-a36b-03e4c7d845ba") {
        repository = me.ownerRepository;
      } else {
        me.formData = {
          ...me.formData,
          profileUuid: me.user.profile.uuid,
          enterpriseUuid: me.enterprise.uuid,
        };
        repository = me.employeeRepository;
      }

      try {
        me.isBusy = true;
        await repository.update(me.record.uuid, me.formData);
        me.$emit("save");
      } catch (error) {
        const { message, status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        me.errorSave(message)
      } finally {
        me.isBusy = false;
      }
    },
  },
};
</script>
