<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row class="mb-2">
        <b-col cols="12">
          <h3>Mi Perfil</h3>
        </b-col>
      </b-row>
      <section>
        <b-card no-body class="card-global overflow-hidden">
          <b-row class="mb-2">
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Nombres</label>
                <b-form-input
                  size="sm"
                  :value="user?.names"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Apellidos</label>
                <b-form-input
                  size="sm"
                  :value="user?.last_names"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Usuario</label>
                <b-form-input size="sm" :value="getUser?.username" disabled />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Tipo de identificación</label>
                <b-form-input
                  size="sm"
                  :value="user?.type_identification"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label for="identification" class="label_input_black"
                  >Identificación</label
                >
                <b-form-input
                  size="sm"
                  :value="user?.identification"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Correo electrónico</label>
                <b-form-input
                  size="sm"
                  :value="user?.email"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Celular</label>
                <b-form-input
                  size="sm"
                  :value="user?.cellphone"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Dirección</label>
                <b-form-input
                  size="sm"
                  :value="user?.address"
                  disabled
                />
              </div>
            </b-col>
            <b-col lg="4" md="6" sm="12" class="mb-3">
              <div role="group">
                <label class="label_input_black">Género</label>
                <b-form-input
                  size="sm"
                  :value="user?.gender"
                  disabled
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="footer-modal-customer">
            <b-col lg="12" md="12" sm="12" class="d-flex justify-content-end">
              <b-button class="btn btn-warning" size="lg" @click="handleEditing">
                <b-icon-pencil-square />
                Actualizar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </section>
    </b-container>

    <base-modal title="Actualizar Perfil" id="update-user-model">
      <template v-slot:form>
        <form-my-profile
          :record="record"
          :profileUuid="profileUuid"
          @save="save"
        />
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BIconPencilSquare,
  BCard,
} from "bootstrap-vue";
import FormMyProfile from "./FormMyProfile";
import BaseModal from "@/components/base/BaseModal";
import { mapGetters, mapActions } from "vuex";
import ButtonBack from "@/components/buttons/ButtonBack";
import messageMixin from "@/mixins/messageMixin";

export default {
  inject: ["ownerRepository", "employeeRepository", "userRepository"],
  mixins: [messageMixin],
  components: {
    BContainer,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BIconPencilSquare,
    BCard,
    FormMyProfile,
    BaseModal,
    ButtonBack,
  },
  data() {
    return {
      record: null,
      user: null,
      profileUuid: null,
    };
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    ...mapActions("user", ["loadUser", "updateUser"]),
    handleEditing() {
      const me = this;
      me.record = { ...me.user, username: me.getUser.username };
      me.$bvModal.show("update-user-model");
    },
    async loadData() {
      const me = this;
      let repository = null;
      if (me.profileUuid == "bcaa1cc2-72f3-4183-a36b-03e4c7d845ba") {
        repository = me.ownerRepository;
      } else {
        repository = me.employeeRepository;
      }
      try {
        const { data } = await repository.find();
        me.user = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async loadUser() {
      const me = this;
      try {
        const { data } = await me.userRepository.find(me.getUser.uuid);
        me.$store.dispatch("user/updateUser", data);
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    async save() {
      const me = this;
      me.messageSuccess();
      me.$bvModal.hide("update-user-model");
      me.loadData();
    },
  },
  async mounted() {
    window.scroll(0, 0);
    const me = this;
    me.profileUuid = me.getUser.profile.uuid;
    me.loadData();
  },
};
</script>
