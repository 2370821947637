<template>
  <button class="bg-wpp" @click.prevent="handleClick">
    <img src="@/assets/images/whatsapp.png" alt="Guiia Tour" />
  </button>
</template>

<script>
  export default {
    methods: {
      handleClick() {
        let a = document.createElement('a');
        const link = `https://api.whatsapp.com/send?phone=${573002859182}&text=Me%20gustaría%20hablar%20con%20un%20asesor.`;
        a.href = link;
        a.target = '_blank';
        a.click();
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>