<template>
  <b-container fluid class="container__login">
    <div class="login__content">
      <h2 class="title__login">Cambiar contraseña</h2>
      <h6 class="mb-3" v-if="isFisrtSession">
        <strong>Acción obligatoria</strong>. Por ser la primera vez que inicia
        sesión, debes cambiar tu contraseña
      </h6>
      <b-form @submit.prevent="changePassword" autocomplete="off">
        <div id="curretPassword">
          <div class="input__field" v-if="isFisrtSession">
            <span class="icono__input">
              <b-icon-lock-fill />
            </span>
            <input
              :type="type"
              placeholder="Contraseña actual"
              v-model="user.curretPassword"
            />
            <span class="icon_eye" @click.prevent="seePassword">
              <b-icon-eye-fill v-if="type == 'password'" />
              <b-icon-eye-slash-fill v-else />
            </span>
          </div>
        </div>
        <div id="password">
          <div class="input__field">
            <span class="icono__input">
              <b-icon-lock-fill />
            </span>
            <input
              :type="type"
              placeholder="Contraseña nueva"
              v-model="user.newPassword"
            />
            <span class="icon_eye" @click.prevent="seePassword">
              <b-icon-eye-fill v-if="type == 'password'" />
              <b-icon-eye-slash-fill v-else />
            </span>
          </div>
        </div>
        <div class="olvidar__password" v-if="!isFisrtSession">
          <a class="a__login" @click.prevent="recoveryPasswod"
            >Vuelve a generar otro link</a
          >
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            type="submit"
            class="btn__ingresar mt-1"
            :disabled="isBusy || $v.$invalid || isDisabled"
          >
            <b-spinner class="mr-2" small v-if="isBusy" />
            Cambiar
          </b-button>
        </div>
      </b-form>
    </div>
    <base-modal id="recovery-password" :title="title" :size="'md'">
      <template v-slot:form>
        <message-recovery v-if="isMessage" />
        <recover-password-form @sendEmail="sendEmail" v-else />
      </template>
    </base-modal>
  </b-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import messageMixin from "@/mixins/messageMixin";
import {
  BIconLockFill,
  BButton,
  BContainer,
  BForm,
  BSpinner,
  BIconEyeFill,
  BIconEyeSlashFill,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import RecoverPasswordForm from "@/app/auth/login/modal/RecoverPasswordForm";
import MessageRecovery from "@/app/auth/login/modal/MessageRecovery";

export default {
  inject: ["authRepository"],
  mixins: [messageMixin],
  data() {
    const me = this;
    return {
      user: {
        newPassword: "",
        token: me.$route.query.token,
        curretPassword: "",
      },
      isBusy: false,
      isMessage: false,
      title: "Recuperar contraseña",
      type: "password",
      isFisrtSession: false,
      isDisabled: false,
    };
  },
  components: {
    BIconLockFill,
    BButton,
    BContainer,
    BForm,
    BSpinner,
    BIconEyeFill,
    BIconEyeSlashFill,
    BaseModal,
    RecoverPasswordForm,
    MessageRecovery,
  },
  validations() {
    const me = this;
    return me.isFisrtSession
      ? {
          user: {
            curretPassword: {
              required,
            },
            newPassword: {
              required,
            },
          },
        }
      : {
          user: {
            newPassword: {
              required,
            },
          },
        };
  },
  methods: {
    async changePassword() {
      const me = this;
      try {
        me.isBusy = true;
        const option = me.isFisrtSession
          ? "changePasswordFistSession"
          : "changePassword";
        
        me.user.newPassword = me.user.newPassword.trim();
        me.user.curretPassword = me.user.curretPassword.trim();
        await me.authRepository[option](me.user);
        me.isDisabled = true;
        me.messageSuccess(
          "Contraseña cambiada",
          `Tu contraseña se ha actualizado correctamente. ${
            me.isFisrtSession ? "Inicia sesión con tu nueva clave" : ""
          }`
        );
        localStorage.clear();
        setTimeout(() => {
          me.$router.push("/login");
        }, 4000);
      } catch (error) {
        const me = this;
        me.isDisabled = false;
        const { message } = error.data;

        let title = "Error al cambiar contraseña";
        let messageErr = "Error al intentar cambiar tu contraseña.";

        if (!me.isFisrtSession)
          messageErr =
            "Tu link esta dañado ó a expirado, debes volver a generar otro.";
        if (message == "Wrong email or password!") {
          messageErr = "Contraseña actual erronea.";
        } else if (message == "Password equal") {
          messageErr = "La nueva contraseña no puede ser igual a la actual.";
        }
        me.messageError(title, messageErr);
      } finally {
        me.isBusy = false;
      }
    },
    recoveryPasswod() {
      const me = this;
      me.isMessage = false;
      me.title = "Recuperar contraseña";
      me.$bvModal.show("recovery-password");
    },
    sendEmail() {
      const me = this;
      me.title = "¡Revisa tu bandeja de entrada!";
      me.isMessage = true;
      setTimeout(() => {
        me.$router.push("/login");
      }, 4000);
    },
    seePassword() {
      const me = this;
      if (me.type == "password") {
        me.type = "text";
      } else {
        me.type = "password";
      }
    },
  },

  mounted() {
    window.scroll(0, 0);
    const me = this;
    me.isFisrtSession = me.$route.query.token ? false : true;
  },
};
</script>

<style>
@import "../auth/login/login.css";
</style>
